import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Layout from '../components/Layout';
import * as Api from '../apis';
import useActiveSession from '../hooks/useActiveSession';
import { numberWithCommas } from '../helpers/utility';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from 'recharts';

const useStyles = makeStyles(theme => ({
  summaryContainer: {
    background: '#bfdfdc',
    margin: '20px',
    borderRadius: '10px',
  },
  statBoxContainer: {
    background: `${theme.palette.primary.main}`,
    margin: '20px',
    padding: '10px',
    borderRadius: '5px',
    color: 'white',
    alignItems: 'center',
  },
  statLabel: {
    flex: 1,
    fontSize: '18px',
  },
  statNumber: {
    flex: 1,
    textAlign: 'right',
    fontSize: '30px',
  },
  statDifference: {
    flex: 1,
    textAlign: 'right',
    fontSize: '18px',
  },
  timelineContainer: {
    width: props => `${props.percentWidth}%`,
    height: '400px',
    background: '#bfdfdc',
    margin: '10px 20px',
    borderRadius: '10px',
  },
  provinceContainer: {
    background: '#bfdfdc',
    margin: '20px',
    padding: '10px 0px',
    borderRadius: '10px',
  },
  provinceBoxContainer: {
    padding: '10px 15px',
    margin: '0px 10px',
    color: `${theme.palette.primary.main}`,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  provinceLabel: {
    flex: 1,
  },
  provinceNumber: {
    flex: 1,
    textAlign: 'right',
  },
  greenText: {
    color: '#65e465',
  },
  redText: {
    color: 'red',
  },
}));

const MAP_THAI_PROVINCE = {
  Bangkok: 'กรุงเทพมหานคร',
  'Samut Prakan': 'สมุทรปราการ',
  Nonthaburi: 'นนทบุรี',
  'Pathum Thani': 'ปทุมธานี',
  'Phra Nakhon Si Ayutthaya': 'พระนครศรีอยุธยา',
  'Ang Thong': 'อ่างทอง',
  Lopburi: 'ลพบุรี',
  'Sing Buri': 'สิงห์บุรี',
  'Chai Nat': 'ชัยนาท',
  Saraburi: 'สระบุรี',
  Chonburi: 'ชลบุรี',
  Rayong: 'ระยอง',
  Chanthaburi: 'จันทบุรี',
  Trat: 'ตราด',
  Chachoengsao: 'ฉะเชิงเทรา',
  Prachinburi: 'ปราจีนบุรี',
  'Nakhon Nayok': 'นครนายก',
  'Sa Kaeo': 'สระแก้ว',
  'Nakhon Ratchasima': 'นครราชสีมา',
  Buriram: 'บุรีรัมย์',
  Surin: 'สุรินทร์',
  Sisaket: 'ศรีสะเกษ',
  'Ubon Ratchathani': 'อุบลราชธานี',
  Yasothon: 'ยโสธร',
  Chaiyaphum: 'ชัยภูมิ',
  'Amnat Charoen': 'อำนาจเจริญ',
  'Bueng Kan': 'บึงกาฬ',
  'Nong Bua Lamphu': 'หนองบัวลำภู',
  'Khon Kaen': 'ขอนแก่น',
  'Udon Thani': 'อุดรธานี',
  Loei: 'เลย',
  'Nong Khai': 'หนองคาย',
  'Maha Sarakham': 'มหาสารคาม',
  'Roi Et': 'ร้อยเอ็ด',
  Kalasin: 'กาฬสินธุ์',
  'Sakon Nakhon': 'สกลนคร',
  'Nakhon Phanom': 'นครพนม',
  Mukdahan: 'มุกดาหาร',
  'Chiang Mai': 'เชียงใหม่',
  Lamphun: 'ลำพูน',
  Lampang: 'ลำปาง',
  Uttaradit: 'อุตรดิตถ์',
  Phrae: 'แพร่',
  Nan: 'น่าน',
  Phayao: 'พะเยา',
  'Chiang Rai': 'เชียงราย',
  'Mae Hong Son': 'แม่ฮ่องสอน',
  'Nakhon Sawan': 'นครสวรรค์',
  'Uthai Thani': 'อุทัยธานี',
  'Kamphaeng Phet': 'กำแพงเพชร',
  Tak: 'ตาก',
  Sukhothai: 'สุโขทัย',
  Phitsanulok: 'พิษณุโลก',
  Phichit: 'พิจิตร',
  Phetchabun: 'เพชรบูรณ์',
  Ratchaburi: 'ราชบุรี',
  Kanchanaburi: 'กาญจนบุรี',
  'Suphan Buri': 'สุพรรณบุรี',
  'Nakhon Pathom': 'นครปฐม',
  'Samut Sakhon': 'สมุทรสาคร',
  'Samut Songkhram': 'สมุทรสงคราม',
  Phetchaburi: 'เพชรบุรี',
  'Prachuap Khiri Khan': 'ประจวบคีรีขันธ์',
  'Nakhon Si Thammarat': 'นครศรีธรรมราช',
  Krabi: 'กระบี่',
  'Phang Nga': 'พังงา',
  Phuket: 'ภูเก็ต',
  'Surat Thani': 'สุราษฎร์ธานี',
  Ranong: 'ระนอง',
  Chumphon: 'ชุมพร',
  Songkhla: 'สงขลา',
  Satun: 'สตูล',
  Trang: 'ตรัง',
  Phatthalung: 'พัทลุง',
  Pattani: 'ปัตตานี',
  Yala: 'ยะลา',
  Narathiwat: 'นราธิวาส',
};

const StatBox = ({ label, number, difference, positive }) => {
  const percentWidth = ((window.innerWidth - 40) / window.innerWidth) * 100;
  const classes = useStyles({ percentWidth });
  const isPositive = Math.sign(difference) === 1;
  const colorClass =
    positive === isPositive ? classes.greenText : classes.redText;

  return (
    <Box display='flex' className={classes.statBoxContainer}>
      <span className={classes.statLabel}>{label}</span>
      <span className={classes.statNumber}>{numberWithCommas(number)}</span>
      <span className={`${classes.statDifference} ${colorClass}`}>
        ({isPositive && '+'}
        {difference})
      </span>
    </Box>
  );
};

const ProvinceBox = ({ label, number }) => {
  const classes = useStyles();

  return (
    <Box display='flex' className={classes.provinceBoxContainer}>
      <span className={classes.provinceLabel}>{label}</span>
      <span className={classes.provinceNumber}>{numberWithCommas(number)}</span>
    </Box>
  );
};

const CustomizedLabel = ({ x, y, stroke, value }) => {
  return (
    <text x={x} y={y} dy={-10} fill={stroke} fontSize={12} textAnchor='middle'>
      {numberWithCommas(value)}
    </text>
  );
};

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor='end'
        fill='black'
        transform='rotate(-45)'
      >
        {payload.value.substring(0, 5)}
      </text>
    </g>
  );
};

const CustomizedDot = props => {
  const { cx, cy } = props;

  return (
    <text
      x={cx - 4}
      y={cy + 9}
      width={20}
      height={20}
      style={{ fontSize: 25 }}
      fill='#3b776f'
    >
      &bull;
    </text>
  );
};

const TimelineChart = ({ data }) => {
  return (
    <ResponsiveContainer width='90%' height='70%'>
      <LineChart
        data={data}
        margin={{ top: 20, right: 20, left: 20, bottom: 0 }}
      >
        <CartesianGrid horizontal={false} />
        <Legend
          align='left'
          verticalAlign='top'
          height={36}
          content={value => (
            <span style={{ color: 'black' }}>จำนวนผู้ติดเชื้อ</span>
          )}
        />
        <XAxis dataKey='Date' height={60} tick={<CustomizedAxisTick />} />
        <YAxis
          tickFormatter={value => numberWithCommas(value)}
          tickMargin={10}
          tick={{ fill: 'black', strokeWidth: 2 }}
          domain={[
            dataMin => parseInt(dataMin / 1000) * 1000,
            dataMax => parseInt(dataMax / 1000 + 1) * 1000,
          ]}
        />
        <Line
          type='monotone'
          dataKey='Confirmed'
          stroke='#3b776f'
          dot={<CustomizedDot />}
          label={<CustomizedLabel />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const StatisticPage = () => {
  const classes = useStyles();
  const [summary, setSummary] = useState(null);
  const [provinces, setProvinces] = useState(null);
  const [timelines, setTimelines] = useState(null);

  useActiveSession(() => {
    Api.getTodaySummaryStatistic().then(response => {
      setSummary(response.data);
    });
    Api.getTimelineStatistic().then(response => {
      const data = response.data.Data;
      const lastSevenDays = data.slice(Math.max(data.length - 7, 1));
      setTimelines(lastSevenDays);
    });
    Api.getTodayProvinceStatistic().then(response => {
      const provinces = response.data.Province;
      const provinceList = [];
      for (const province in provinces) {
        provinceList.push({
          name: province,
          number: provinces[province],
        });
      }
      setProvinces(provinceList);
    });
  });

  return (
    <Layout>
      {summary && (
        <Box className={classes.summaryContainer}>
          <h2 align='center'>สถิติรวมประเทศไทย</h2>
          <h4 align='center'>({summary.UpdateDate})</h4>
          <StatBox
            label={'ติดเชื้อสะสม'}
            positive={false}
            number={summary.Confirmed}
            difference={summary.NewConfirmed}
          />
          <StatBox
            label={'หายแล้ว'}
            positive={true}
            number={summary.Hospitalized}
            difference={summary.NewRecovered}
          />
          <StatBox
            label={'รักษาตัว'}
            positive={false}
            number={summary.Hospitalized}
            difference={summary.NewHospitalized}
          />
          <StatBox
            label={'เสียชีวิต'}
            positive={false}
            number={summary.Deaths}
            difference={summary.NewDeaths}
          />
        </Box>
      )}
      {timelines && (
        <Box className={classes.timelineContainer}>
          <h2 align='center' style={{ margin: '20px 0px 0px 0px' }}>
            กราฟผู้ติดเชื้อสะสมรายวัน
          </h2>
          <TimelineChart data={timelines} />
          <h4 align='center'>1 สัปดาห์ที่ผ่านมา</h4>
        </Box>
      )}
      {provinces && (
        <Box className={classes.provinceContainer}>
          <h2 align='center'>แยกตามจังหวัด</h2>
          {provinces.map(
            (province, index) =>
              province.name !== 'Unknown' && (
                <ProvinceBox
                  key={index}
                  label={MAP_THAI_PROVINCE[province.name]}
                  number={province.number}
                />
              ),
          )}
        </Box>
      )}
    </Layout>
  );
};

export default StatisticPage;
